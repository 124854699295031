import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: 'https://annotation-detect.aiotlab-annotation.com/',
});

export default axiosInstance;

export async function getMainObject({ file, points, satelliteMode }) {
	try {
		const formData = new FormData();
		formData.append('image_file', file);
		formData.append('x1', points.x1);
		formData.append('y1', points.y1);
		formData.append('x2', points.x2);
		formData.append('y2', points.y2);
		const res = await axiosInstance.post('/get_main_object', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'accept': 'application/json'
			},
		});
		return res.data
	} catch (error) {
		console.log('err', error);
	}
}

export async function saveImg({ file }) {
	try {
		const formData = new FormData();
		formData.append('file', file);
		const res = await axiosInstance.post('/save_img', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'accept': 'application/json',
			},
		});
		return res.data
	} catch (error) {
		console.log('err', error);
	}
}

export async function addSelection({ polyAnnotations, mousePos }) {
	try {
		let data = {
			'image_id': polyAnnotations[0].image_id,
			'rectangle':
				[[
					polyAnnotations[0].rectangle.x1,
					polyAnnotations[0].rectangle.y1
				], [
					polyAnnotations[0].rectangle.x2,
					polyAnnotations[0].rectangle.y2
				]],
			'selected_points': polyAnnotations[0].points,
			'click_point': mousePos
		}
		const res = await axiosInstance.post('/add_selection', JSON.stringify(data), {
			headers: { 'Content-type': 'application/json; charset=UTF-8' },
		});
		return res.data
	} catch (error) {
		console.log('err', error);
	}
}

export async function removeSelection({ polyAnnotations, mousePos }) {
	try {
		let data = {
			'image_id': polyAnnotations[0].image_id,
			'rectangle':
				[[
					polyAnnotations[0].rectangle.x1,
					polyAnnotations[0].rectangle.y1
				], [
					polyAnnotations[0].rectangle.x2,
					polyAnnotations[0].rectangle.y2
				]],
			'selected_points': polyAnnotations[0].points,
			'click_point': mousePos
		}
		const res = await axiosInstance.post('/remove_selection', JSON.stringify(data), {
			headers: { 'Content-type': 'application/json; charset=UTF-8' },
		});
		return res.data
	} catch (error) {
		console.log('err', error);
	}
}

export async function savePoly({ image_id, saved_points }) {
	try {
		const formData = new FormData();
		formData.append('image_id', image_id);
		formData.append('saved_points', saved_points);
		const res = await axiosInstance.post('/save_poly', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return res.data
	} catch (error) {
		console.log('err', error);
	}
}

export async function loadPoly({ image_id }) {
	try {
		const formData = new FormData();
		formData.append('image_id', image_id);
		const res = await axiosInstance.post('/load_poly', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return res.data
	} catch (error) {
		console.log('err', error);
	}
}