// custom hooks
import { useContext, useState, useEffect } from "react"
import AuthContext from "../../store/auth-context";
import ThemeContext from "../../store/theme-context";

export const useAuth = () => {
  return useContext(AuthContext)
}

export const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    try {
      const localValue = window.localStorage.getItem(key);
      return localValue ? JSON.parse(localValue) : initialValue;
    } catch (err) {
      console.log(err)
      return initialValue;
    }
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export const useCustomTheme = () => {
  return useContext(ThemeContext)
}