import { useState, useEffect } from "react";
import ThemeContext from "./theme-context";

const CustomThemeProvider = ({ children }) => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const themeStorage =
    localStorage.getItem("antt.theme") || (defaultDark ? "dark" : "light");
  const [customTheme, setCustomTheme] = useState(themeStorage);

  const handleOnChangeTheme = () => {
    setCustomTheme(customTheme === "dark" ? "light" : "dark");
  };

  const value = {
    customTheme,
    handleOnChangeTheme,
  };
  useEffect(() => {
    document.documentElement.setAttribute("color-scheme", customTheme);
    localStorage.setItem("antt.theme", customTheme);
  }, [customTheme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default CustomThemeProvider;
