import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { login } from "../common/auth"
import AuthContext from "./auth-context"

const AuthProvider = ({ children }) => {
    const tokenStorage = localStorage.getItem('token') || null
    const [token, setToken] = useState(tokenStorage)
    const [username, setUsername] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    
    const handleLogin = async ({ username, password }) => {
        const res = await login({ username, password })
        if (res.success) {
            localStorage.setItem('token', res.data.token)
            setToken(res.data.token)
            const origin = location.state?.from?.pathname || '/home';
            navigate(origin);
        } else {
            return res.msg
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('projectId')
        setToken(null)
        navigate('/login');
    }

    const value = {
        token: token,
        onLogin: handleLogin,
        onLogout: handleLogout
    }

    return (
        <AuthContext.Provider value = { value } >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider