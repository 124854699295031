import axiosInstance from "../api"

export async function login({ username, password }) {
    try {
        const res = await axiosInstance.post('/login', {
            username,
            password
        })
        return {
            success: true,
            data: res.data
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            msg: error.response?.data.detail || 'Something was wrong!'
        }
    }
}

export async function signUp({ username, password, confirmPassword }) {
    try {
        const res = await axiosInstance.post('register', {
            username,
            password,
            confirm_password :confirmPassword
        })
        return {
            success: true,
            data: res.data
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            msg: error.response?.data.detail || 'Something was wrong!'
        }
    }
}