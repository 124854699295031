import React, { Component, Suspense } from 'react';
import {
  HashRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useAuth } from './common/hook';
import AuthProvider from './store/AuthProvider';
import CustomThemeProvider from "./store/CustomThemeProvider";
import './index.css';
// import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/common/Login'));
const SignUp = React.lazy(() => import('./pages/common/SignUp'));
const Page404 = React.lazy(() => import('./pages/common/Page404'));
const ForgotPassword = React.lazy(() =>
  import('./pages/common/ForgotPassword')
);

const ProtectedRouter = ({ children }) => {
  const location = useLocation();
  const { token } = useAuth();
  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return children
}
class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <CustomThemeProvider>
            <AuthProvider>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route
                exact
                path="/signup"
                name="Register Page"
                element={<SignUp />}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forgot Password Page"
                element={<ForgotPassword />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route
                exact
                path="/"
                name="Home"
                element={
                  <ProtectedRouter>
                    <DefaultLayout />
                  </ProtectedRouter>
                }
              />
              <Route
                path="*"
                name="Home"
                element={
                  <ProtectedRouter>
                    <DefaultLayout />
                  </ProtectedRouter>
                }
              />
            </Routes>
          </AuthProvider>
          </CustomThemeProvider>
          
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
